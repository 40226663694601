import { default as _91_46_46_46locationSlug_93PygVLI20KKMeta } from "/vercel/path0/pages/[...locationSlug].vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as indexM0FRscb8qgMeta } from "/vercel/path0/pages/biz/index.vue?macro=true";
import { default as index34nawk52aJMeta } from "/vercel/path0/pages/biz/success/index.vue?macro=true";
import { default as index1lVmB4IgMRMeta } from "/vercel/path0/pages/contact-us/index.vue?macro=true";
import { default as indexgqoUxXFqBAMeta } from "/vercel/path0/pages/go/index.vue?macro=true";
import { default as indexfMHZkUaQDzMeta } from "/vercel/path0/pages/go/success/index.vue?macro=true";
import { default as indexfgiwrlFjmpMeta } from "/vercel/path0/pages/index/index.vue?macro=true";
import { default as indexz1P4JbDdkXMeta } from "/vercel/path0/pages/magicfinder/index.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as indexDEa5euBXwJMeta } from "/vercel/path0/pages/onboarding/result/index.vue?macro=true";
import { default as index4eQPsC0hQGMeta } from "/vercel/path0/pages/picks/[uid]/index.vue?macro=true";
import { default as indexYPAS6e4VgdMeta } from "/vercel/path0/pages/picksV2/[uid]/index.vue?macro=true";
import { default as indexa7SLYrUpNFMeta } from "/vercel/path0/pages/privacy/index.vue?macro=true";
import { default as index6kiyFIURhyMeta } from "/vercel/path0/pages/stays/index.vue?macro=true";
import { default as index9OiDgfx7OcMeta } from "/vercel/path0/pages/terms/index.vue?macro=true";
import { default as index7s70P90RVXMeta } from "/vercel/path0/pages/trips/[tripId]/bookings/[id]/index.vue?macro=true";
import { default as indexlvnsc93CTeMeta } from "/vercel/path0/pages/trips/[tripId]/deals/index.vue?macro=true";
import { default as constsETp91uKHpuMeta } from "/vercel/path0/pages/trips/[tripId]/hotels/[hotelId]/deals/consts.ts?macro=true";
import { default as indexg6wiNg1cTpMeta } from "/vercel/path0/pages/trips/[tripId]/hotels/[hotelId]/deals/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "locationSlug",
    path: "/:locationSlug(.*)*",
    component: () => import("/vercel/path0/pages/[...locationSlug].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexkppHktB81xMeta || {},
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "biz",
    path: "/biz",
    component: () => import("/vercel/path0/pages/biz/index.vue").then(m => m.default || m)
  },
  {
    name: "biz-success",
    path: "/biz/success",
    component: () => import("/vercel/path0/pages/biz/success/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "go",
    path: "/go",
    component: () => import("/vercel/path0/pages/go/index.vue").then(m => m.default || m)
  },
  {
    name: "go-success",
    path: "/go/success",
    component: () => import("/vercel/path0/pages/go/success/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "magicfinder",
    path: "/magicfinder",
    component: () => import("/vercel/path0/pages/magicfinder/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-result",
    path: "/onboarding/result",
    component: () => import("/vercel/path0/pages/onboarding/result/index.vue").then(m => m.default || m)
  },
  {
    name: "picks-uid",
    path: "/picks/:uid()",
    component: () => import("/vercel/path0/pages/picks/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "picksV2-uid",
    path: "/picksV2/:uid()",
    component: () => import("/vercel/path0/pages/picksV2/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/vercel/path0/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "stays",
    path: "/stays",
    meta: index6kiyFIURhyMeta || {},
    component: () => import("/vercel/path0/pages/stays/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/vercel/path0/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "trips-tripId-bookings-id",
    path: "/trips/:tripId()/bookings/:id()",
    component: () => import("/vercel/path0/pages/trips/[tripId]/bookings/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "trips-tripId-deals",
    path: "/trips/:tripId()/deals",
    meta: indexlvnsc93CTeMeta || {},
    component: () => import("/vercel/path0/pages/trips/[tripId]/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "trips-tripId-hotels-hotelId-deals-consts",
    path: "/trips/:tripId()/hotels/:hotelId()/deals/consts",
    component: () => import("/vercel/path0/pages/trips/[tripId]/hotels/[hotelId]/deals/consts.ts").then(m => m.default || m)
  },
  {
    name: "trips-tripId-hotels-hotelId-deals",
    path: "/trips/:tripId()/hotels/:hotelId()/deals",
    meta: indexg6wiNg1cTpMeta || {},
    component: () => import("/vercel/path0/pages/trips/[tripId]/hotels/[hotelId]/deals/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/bizV2",
    component: component_45stub5X4Ei38PMg
  }
]