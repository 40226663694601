export const Urls = {
    Home: () => '/',
    SignIn: ({ redirectAfter }: { redirectAfter?: string } = {}) =>
        `/sign-in${redirectAfter ? `?${SearchParams.RedirectAfter}=${encodeURIComponent(redirectAfter)}` : ''}`,
    SignUp: () => '/sign-up',
    Auth: ({ redirectAfter }: { redirectAfter?: string } = {}) =>
        `/auth${redirectAfter ? `?${SearchParams.RedirectAfter}=${encodeURIComponent(redirectAfter)}` : ''}`,
    Account: () => '/account',
    ResetPassword: () => '/reset-password',
    ChangePassword: () => '/change-password',
    Trips: () => `/stays`,
    TripHotelDeals: ({
        tripId,
        hotelId,
    }: {
        tripId: string
        hotelId: string
    }) => `/trips/${tripId}/hotels/${hotelId}/deals`,
    TripDeals: ({ tripId }: { tripId: string }) => `/trips/${tripId}/deals`,
    Contact: () => '/contact',
    Booking: ({ id }: { id: string }) => `/bookings/${id}`,
    BookingStatus: ({ id, tripId }: { id: string; tripId: string }) =>
        `/trips/${tripId}/bookings/${id}`,
    Trip: ({ id }: { id: string }) => `/trips/${id}`,
    MagicFinder: () => '/magicfinder',
    Privacy: () => '/privacy',
    Terms: () => '/terms',
    ContactUs: () => '/contact-us',
    Enterprise: () => '/biz',
    Onboarding: ({
        email,
        name,
        phone,
    }: {
        email: string
        name: string
        phone: string
    }) => `/onboarding?name=${name}&email=${email}&phone=${phone}`,
    OnboardingTokenError: () => '/onboarding/result?state=error',
    OnboardingSuccessPage: () => '/onboarding/result?state=success',
    BizSuccess: () => '/biz/success',
}

export const SearchParams = {
    RedirectAfter: 'redirectAfter',
}
